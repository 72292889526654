import { APICore } from "./apiCore";
const api = new APICore();
const apiPathName = (str: string): string => `/admin/api/public/` + str;
class Handlers {
  /** Notification API ROUTES */
  getNotifications() {
    return api.get(apiPathName("notifications"), {});
  }
  /**  USER API ROUTES */
  getAllUsers() {
    return api.get("/admin/api/public/users/list", null);
  }
  getUserById(userId: string) {
    return api.get("/admin/api/public/user/get", { userId });
  }
  getUserByPhone(phone: string) {
    return api.get("/admin/api/public/user/get", { phone });
  }
  createNewUser(data: any) {
    return api.create(apiPathName("user"), data);
  }
  banUser(data: any) {
    return api.updatePatch("/admin/api/public/user", data);
  }
  updateExistingUser(data: any) {
    return api.update("/admin/api/public/user", data);
  }
  deleteUser(userId: string) {
    return api.delete(`/admin/api/public/user?userId=${userId}`);
  }

  /** SUBMISSIONS API ROUTES */
  getAllLatestSubmissions() {
    return api.get("/admin/api/public/submissions/get", null);
  }
  getSubmissionsDetails(id: string) {
    return api.get("/admin/api/public/submissions/get", {
      submissionId: id,
    });
  }
  updateExistingSubmissionRequest(data: any) {
    return api.update("/admin/api/public/submissions", data);
  }
  deleteSubmissionRequest(submissionRequestID: string) {
    return api.delete(
      `/admin/api/public/submissions?submissionId=${submissionRequestID}`
    );
  }
  addNewSubmission(data: any) {
    return api.create("/api/v1/create-new-submission", data);
  }
  /** DRIVER API ROUTES */

  /**
   * Creates a new driver using the provided data.
   *
   * @param {any} data - The data for creating the driver.
   * @return {any} The response from the API call.
   */
  createNewDriver(data: any) {
    return api.create("/admin/api/public/driver", data);
  }
  /**
   * Updates an existing driver.
   *
   * @param {any} data - The data to update the driver with.
   * @return {Promise<any>} - A promise that resolves with the updated driver data.
   */
  updateExistingDriver(data: any) {
    return api.update("/admin/api/public/driver", data);
  }
  /**
   * Resets the password for a driver.
   *
   * @param {string} driverId - The ID of the driver.
   * @return {Promise} A promise that resolves to the updated driver object.
   */
  resetDriverPassword(driverId: string) {
    return api.updatePatch("/admin/api/public/driver", { driverId });
  }
  /**
   * Deletes a driver with the given driverId.
   *
   * @param {string} driverId - The ID of the driver to be deleted.
   * @return {Promise} A promise that resolves when the driver is deleted.
   */
  deleteDriver(driverId: string) {
    return api.delete(`/admin/api/public/driver?driverId=${driverId}`);
  }
  /**
   * Retrieves all drivers from the API.
   *
   * @return {Promise<any>} - A promise that resolves to an array of driver objects.
   */
  getAllDrivers() {
    return api.get("/admin/api/public/drivers/all", null);
  }
  /**
   * Retrieves the ID and name of all drivers.
   *
   * @return {Promise<{ name: string, id: number }[]>} Returns a promise that resolves to an array
   * of objects containing the name and ID of each driver.
   */
  getAllDriversIdAndName() {
    return api.get("/admin/api/public/drivers/all", { name: true, id: true });
  }
  /**
   * Retrieves a driver by their ID.
   *
   * @param {string} driverId - The ID of the driver to retrieve.
   * @return {Promise<any>} A promise that resolves with the driver object.
   */
  getDriverById(driverId: string) {
    return api.get("/admin/api/public/driver/get", { driverId });
  }
  /**
   * Assigns a driver to a submission.
   *
   * @param {Object} data - The data object containing the submissionId and driverId.
   * @param {string} data.submissionId - The ID of the submission.
   * @param {string} data.driverId - The ID of the driver.
   * @return {Promise} A promise that resolves to the result of the API call.
   */
  assignDriverToSubmisson(data: { submissionId: string; driverId: string }) {
    return api.create("/admin/api/public/assign/driver", data);
  }
  /**
   * Updates the delivery status of a submission.
   *
   * @param {Object} data - The data object containing the submissionId and status.
   * @param {string} data.submissionId - The ID of the submission.
   * @param {string[]} data.status - An array of status values to update.
   * @return {Promise} A Promise that resolves with the result of the update.
   */
  updateDeliveryStatus(data: { submissionId: string; status: string[] }) {
    return api.updatePatch(
      "/admin/api/public/submission/update/delivery-status",
      data
    );
  }
  /**
   * Updates the status of a submission request.
   *
   * @param {Object} data - The data object containing the submission ID and the new status.
   * @param {string} data.submissionId - The ID of the submission.
   * @param {string} data.status - The new status of the submission.
   * @return {Promise<any>} A promise that resolves with the response from the API.
   */
  updateSubmissionRequestStatus(data: {
    submissionId: string;
    status: string;
  }) {
    return api.updatePatch("/admin/api/public/submission/update/status", data);
  }
  getSubmissionByStatus(status: string = "Pending") {
    return api.get(apiPathName("submission-by-status"), { status });
  }

  // Analytics
  getAnalytics() {
    return api.get("/admin/api/public/analytics", null);
  }

  // Payouts API ROutes
  getAllPayouts() {
    return api.get("/admin/api/public/payouts", null);
  }
  getDeafultAmount() {
    return api.get("/api/public/amount", null);
  }
  setDeafultAmount(data: any) {
    return api.create(apiPathName("amount"), data);
  }
  updateDeafultAmount(id: string, data: any) {
    return api.updatePatch(apiPathName("amount?amountId=" + id), data);
  }
  bulkAssignDriverToSubmissions(data: any) {
    return api.create("/admin/api/public/assign/driver/bulk", data);
  }
}
const ApiHandlers = new Handlers();
export default ApiHandlers;
